<template>
  <fragment>
    <!-- Area -->
    <el-col class="mb-3" :xs="12" :sm="spanValue">
      <el-select clearable filterable v-model="params.area" value-key="id" placeholder="Area"
        @change="handleChangeArea">
        <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
    </el-col>
    <!-- National -->
    <el-col class="mb-3" :xs="12" :sm="spanValue">
      <el-select clearable filterable v-model="params.national" value-key="id" placeholder="National"
        @change="handleChangeNational">
        <el-option v-for="item in nationalList" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
    </el-col>
    <!-- Regional -->
    <el-col v-if="params.national.id && isShow" class="mb-3" :xs="12" :sm="spanValue">
      <el-select clearable filterable v-model="params.regional" value-key="id" placeholder="Regional"
        @change="handleChangeRegional">
        <el-option v-for="item in regionalList" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
    </el-col>
    <!-- Local -->
    <el-col class="mb-3" :xs="12" :sm="spanValue">
      <el-select clearable filterable v-model="params.local" value-key="id" placeholder="Local">
        <el-option v-for="item in localList" :key="item.id" :label="item.name" :value="item"></el-option>
      </el-select>
    </el-col>
  </fragment>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    spanValue: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {
      isShow: true,
      params: {
        area: {},
        national: {},
        regional: {},
        local: {}
      },
      areaList: [],
      nationalList: [],
      regionalList: [],
      localList: []
    }
  },
  computed: {},
  watch: {
    params: {
      deep: true,
      handler: function (newVal, oldV) {
        setTimeout(() => {
          this.$emit('getOrganizationParams', newVal)
        }, 500)
      }
    }
  },
  methods: {
    handleChangeArea (item) {
      this.nationalList = []
      this.regionalList = []
      this.localList = []
      this.params.local = {}
      this.params.regional = {}
      this.params.national = {}
      if (!item.id) return
      this.getOrganizationsList('', item.id, 'National')
    },

    handleChangeNational (item) {
      this.isShow = item.has_regions != 0
      this.regionalList = []
      this.localList = []
      this.params.local = {}
      this.params.regional = {}
      if (!item.id) return
      if (item.has_regions) {
        this.getOrganizationsList('', item.id, 'Regional')
      } else {
        this.getOrganizationsList('', item.id, 'Local')
      }
    },

    handleChangeRegional (item) {
      this.localList = []
      this.params.local = {}
      if (!item.id) return
      this.getOrganizationsList('', item.id, 'Local')
    },

    // 获取组织列表
    async getOrganizationsList (type, parent_id, judgmentType) {
      let res = await this.$http.getOrganizationList({ type, parent_id })
      if (res.status == 200) {
        if (judgmentType === 'Area') {
          this.areaList = res.data
        } else if (judgmentType === 'National') {
          this.nationalList = res.data
        } else if (judgmentType === 'Regional') {
          this.regionalList = res.data
        } else if (judgmentType === 'Local') {
          this.localList = res.data
        }
      }
    }
  },

  async mounted () {
    this.getOrganizationsList(4, '', 'Area')
    this.$bus.$on('getBusParentDataApply', async (data) => {
      let newVal = JSON.parse(JSON.stringify(data))
      if (Object.keys(newVal).length > 0) {
        if (newVal.area.id) {
          await this.getOrganizationsList('', newVal.area.id, 'National')
        }
        if (newVal.national.id) {
          if (newVal.national.has_regions) {
            this.isShow = true
            await this.getOrganizationsList('', newVal.national.id, 'Regional')
          } else {
            this.isShow = false
            await this.getOrganizationsList('', newVal.national.id, 'Local')
          }
        }
        if (newVal.regional.id) {
          await this.getOrganizationsList('', newVal.regional.id, 'Local')
        }
        this.params = newVal
      }
    })
  },
  beforeDestroy () {
    this.$bus.$off('getBusParentDataApply')
  }
}
</script>
<style src='' lang='scss' scoped>
  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }

  .el-col .el-col-24 {
    max-width: 160px;
  }
</style>
