<template>
  <iq-card body-class="p-0" class="iqCard_projects">
    <div class="image_box">
      <el-image style="
          width: 100%;
          height: 140px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        " fit="cover" :src="cardInfo.main_picture">
        <div slot="error" class="image-slot">
          <img class="kong_img_class" src="@/assets/images/public/kong_img.png" />
        </div>
      </el-image>
      <i class="my_edit" @click.stop="editProject(cardInfo.id)" v-if="is_edit">
        <img src="@/assets/images/public/edit.png" alt="">
      </i>
      <span class="text_shadow" v-if="is_edit">Edit</span>
    </div>
    <div class="userDetail">
      <div class="title-date">
        <h4 class="title">{{ cardInfo.project_name }}</h4>
        <div class="mt-3 d-flex" v-if='cardInfo.is_has_tag'>
          <span class="my_tag"><img src="@/assets/images/public/project_zan.png" alt=""> Endorsed</span>
        </div>
      </div>
    </div>
  </iq-card>
</template>
<script>
  export default {

    data() {
      return {}
    },
    props: {
      cardInfo: {
        type: Object
      },

      is_edit: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      editProject(id) {
        this.$emit('openDialog', id)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .iqCard_projects {
    width: 313px;
    height: 258px;
    position: relative;
    box-shadow: 0px 0px 20px 0px rgba(75, 75, 75, 0.40) !important;

    &:hover {
      box-shadow: 9.19px 9.19px 27.16px 0.39px rgba(75, 75, 75, 0.45) !important;
    }

    .image_box {
      position: relative;

      .my_edit {
        position: absolute;
        color: #fff;
        font-size: 28px;
        top: 12px;
        right: 12px;
      }

      .text_shadow {
        position: absolute;
        color: #fff;
        font-size:16px;
        top: 54px;
        right: 14px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
      }
    }

    .userDetail {
      /* height: calc(100% - 180px); */
      padding: 8px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .img_approve {
        position: absolute;
        bottom: 26px;
        right: 20px;
      }

      .my_tag {
        border: 1.5px solid #EDBE38;
        border-radius: 14.5px;
        padding: 2px 8px;
        text-align: center;
        color: #EDBE38;
        line-height: 24px;
      }

      // 标题和日期
      .title {
        color: #333;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .date {
        font-size: 14px;
        color: #333333;
        padding-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
      }

      .bottom_tex {
        color: #999999;
      }

      // 小头像
      .iq-media {
        width: 24px;
        height: 24px;
        margin-left: -12px !important;

        img {
          width: 100%;
        }
      }

      .iq-media-group .iq-media:first-child {
        margin-left: 0 !important;
      }
    }
  }

  ::v-deep .el-image {
    background: #eff0f2;
  }

  .image-slot {
    height: 100%;
    background-color: #eff0f2;
    margin: 0 auto;
    position: relative;

    .kong_img_class {
      width: 26px;
      height: 20px;
      margin: 0 auto 0;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 600px) {
    .iqCard_projects {
      width: 100%;
    }
  }
</style>